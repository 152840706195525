
import React, { useEffect, useState } from 'react';
import Modal from '../../common/components/Modal';

const PaymentsModal = ({
  showModal,
  setShowModal,
  handleChange,
  sugestions,
  handleSelectedUser,
  paymentState,
  services,
  handleSaveInvoice,
  sending
}
) => {
  const renderSuggestions = () => {
    return sugestions.map(s => <button className="sugestionsBtn" key={s.phone} type="button" data-email={s.email} onClick={handleSelectedUser}>{s.name} {s.email}</button>);
  }

  const renderSercices = () => {
    return services.map(s => <option key={s._id} value={s._id} data-amount={s.price}>{s.name}</option>)
  }

  return (
    <Modal show={showModal} setShowModal={setShowModal}>
      <br />
      <br />
      <div>
        Agregar nueva Factura
        <form>
          <div><label htmlFor="name">Buscar Persona/Empresa):</label>
            <input
              type="text"
              id="search"
              name="search"
              onChange={handleChange}
              autocomplete="off"
              value={paymentState?.search}
            />
            {sugestions.length > 0 && (<div className="sugestionsByName">{renderSuggestions()}</div>)}
          </div>
          <label htmlFor="name">Nombre (Persona/Empresa):</label>
          <input
            type="text"
            id="name"
            name="name"
            onChange={handleChange}
            value={paymentState?.name}
          />
          <label htmlFor="email">Email:</label>
          <input
            type="text"
            id="email"
            name="email"
            onChange={handleChange}
            value={paymentState?.email}
          />
          <label htmlFor="end_date">Telefono:</label>
          <input
            type="text"
            id="phone"
            name="phone"
            onChange={handleChange}
            value={paymentState?.phone}
          />
          <label htmlFor="dni">DNI/NIE/CIF:</label>
          <input
            type="text"
            id="dni"
            name="dni"
            onChange={handleChange}
            defaultValue={paymentState?.dni}
          />
          <label htmlFor="service_id">Servicio:</label>
          <select name="service_id" onChange={handleChange}>
            <option value="">---</option>
            {services.length > 0 && (renderSercices())}
          </select>
          <label htmlFor="price">Monto:</label>
          <input
            type="text"
            id="price"
            name="price"
            onChange={handleChange}
            value={paymentState?.price}
          />
          <label htmlFor="irpf15Retention">Retención del 15% IRPF:</label>
          <input
            type="checkbox"
            id="irpf15Retention"
            name="irpf15Retention"
            onChange={handleChange}
            checked={paymentState?.irpf15Retention}
          />
          {paymentState?.irpf15Retention && (<div>{paymentState?.price * 0.15}</div>)}
          <br />
          <br />
          <div className="save-button">
            <button className={sending ? "button" : "button-success"} onClick={handleSaveInvoice} disabled={sending}>
              Guardar
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default PaymentsModal;
